import { Route, Routes } from 'react-router-dom';
import './sass/main.scss';
import { useState } from 'react';
import AppContext from './context/Appcontext';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalSigner,  } from '@web3modal/ethers5/react'
import Logo from './assets/images/logo.png';
import { Spinner } from '@nextui-org/react';
import { ComingSoonModal, ErrorModal } from './components/backDropComponent';
import PresalePage from './pages/presalePage';

const projectId = 'a6b30bc12f5a5db7c09d0b165d354ca9'

// 2. Set chains
const chains = [42161]

// 3. Create modal
const metadata = {
  name: 'Relay',
  description: 'Innovative DeFi tool for secure OTC trading experience',
  url: 'https://presale-two-chi.vercel.app/',
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains,
  projectId
})




function App() {

  const [ openSideNav, setopenSideNav ] = useState(false);

  const { open, close } = useWeb3Modal();

  const [ openComingSoon, setOpenComingSoon ] = useState(false)

  const { address, chainId, isConnected,   } = useWeb3ModalAccount();

  const { signer, walletProvider } = useWeb3ModalSigner()

  const TradeFactorycontractAddress  = '';

  const MainControllercontractAddress = ''

  const PresaleSmartContractAddress = '0xAb359C65d25c482b8583754919D5d7d937e48A88';

  const claimContract = '0xB6bc656eCb5ed7bbADac666C529B0D64b16Cd83F';

  const RpcUrl = 'https://arbitrum-mainnet.infura.io/v3/a9999d0d4a744c9b893ae34318117d25'

  const [ preLoader, setpreLoader ] = useState(true)


  var userWalletAddress ;

  if ( address ) {
      var addressLength = address.length - 1
      userWalletAddress = `${address[0]}${address[1]}${address[2]}${address[3]}${address[4]}${address[5]}...${address[addressLength-3]}${address[addressLength-2]}${address[addressLength-1]}${address[addressLength]}`
  }else{
    userWalletAddress = null
  }


  setTimeout(() => {
    setpreLoader(false)
  }, 4000);


  const clearCacheData = () => {
    localStorage.clear()
    window.location.reload()
};

  return (
      <div className='mainApp' >

        { openComingSoon ? <ComingSoonModal closeModal={ () => setOpenComingSoon(false) } /> : <></> }

        <AppContext.Provider
          value={{
            sideNav: openSideNav,
            UpdatesideNav: () => setopenSideNav(!openSideNav),
            enableWeb3: () => open(),
            closeWeb3:clearCacheData,
            isWeb3Enabled: isConnected,
            user_account: address,
            displayAccount: userWalletAddress,
            signer:signer,
            walletProvider:walletProvider,
            TradeFactorycontractAddress:TradeFactorycontractAddress,
            MainControllercontractAddress:MainControllercontractAddress,
            RpcUrl:RpcUrl,
            PresaleSmartContractAddress:PresaleSmartContractAddress,
            comingsoon:() => setOpenComingSoon(true)
          }}
        >

        { preLoader ?
        
        <div style={{
          width:'100%',
          height:"100vh",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          flexDirection:"column",
          backgroundColor:"#0D1019"
        }} >

            <img src={Logo} alt='' style={{
              width:"4rem",
              display:"block",
            }} />

            <Spinner size='lg' color='default' style={{
              marginTop:"2rem"
            }} />

        </div>
      
      :
        <Routes>

          <Route path='/' element={ <PresalePage/> } />

      </Routes>


      
      }

        </AppContext.Provider>
      </div>
  );
}

export default App;
