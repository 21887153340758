import UnderlineImg from '../assets/images/underline.png';
import EclipseImg from '../assets/images/Ellipse.png';
import RightImg from '../assets/images/right_img_.png';
import RightEcliImg from '../assets/images/Ellipse_28.png';
import LeftEcliImg from '../assets/images/Ellipse_29.png';
import { PiWarningOctagonFill } from 'react-icons/pi'
import { useContext, useEffect, useState } from 'react';
import AppContext from '../context/Appcontext';
import { ethers } from 'ethers';
import { ERC20ABI, PRESALEABI } from '../constants/abi';
import { Spinner } from '@nextui-org/react';
import { EditableSuccessModal, ErrorModal2 } from './backDropComponent';
import {  motion } from 'framer-motion';


const PublicSaleComponent = () => {

    const { user_account, enableWeb3, signer, PresaleSmartContractAddress, RpcUrl } = useContext(AppContext)
    const [ isLoading, setisLoading ] = useState(false)
    const [ iserror, setiserror ] = useState({
        status:false,
        title:"",
        message:""
    })
    const [ isSuccess, setisSuccess ] = useState({
        status:false,
        title:"",
        message:""
    })
    const [ amountToContribute, setamountToContribute ] = useState('')
    const [ Sold, setSold ] = useState(null)

    const approveHandler = async () => {

        setisLoading(true)

        try{

            if ( amountToContribute === '' ) {
                setisLoading(false)
                return
            }

            let wei = parseInt(amountToContribute,10)
            // wei = wei * 10000000000000000000
            wei = ethers.utils.parseEther(amountToContribute)

            const ApproveToken = new ethers.Contract('0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',ERC20ABI,signer)


            const approveResponse = await ApproveToken.approve(
                PresaleSmartContractAddress,
                wei
            )

            if ( approveResponse ) {
                setisLoading(false)
                setisSuccess({
                    status:true,
                    title:"Approval Successful",
                    message:`You have successfully approved ${amountToContribute} WETH, you can now contribute it by clicking on the "Contribut WETH" button`
                })
            }


        }
        catch(error){
            // console.log(error)
            setisLoading(false)
            setiserror({
                status:true,
                title:"Something went wrong",
                message:"Please make sure you have enough WETH and gas fee in your wallet"
            })
        }

    }

    const contributeHandler = async () => {

        setisLoading(true)

        try{

            if ( amountToContribute === '' ) {
                setisLoading(false)
                return
            }

            let wei = parseInt(amountToContribute,10)
            // wei = wei * 10000000000000000000
            wei = ethers.utils.parseEther(amountToContribute)


            const contribute = new ethers.Contract(PresaleSmartContractAddress,PRESALEABI,signer)

            const contributeResponse = await contribute.buyTokens(
                wei,
                user_account
            )

            if( contributeResponse ){
                setisLoading(false)
                setamountToContribute('')
                setisSuccess({
                    status:true,
                    title:"Success",
                    message:`You have successfully contributed ${amountToContribute} WETH to this project.`
                })
            }
        }
        catch(error){
            // console.log(error)
            setisLoading(false)
            setiserror({
                status:true,
                title:"Something went wrong",
                message:`Please make sure you approve the amount of WETH you want to contribute. You can do so by clicking on the "Approve WETH" button`
            })
        }

    }

    const getProgress = async () => {

        try{

            const provider = new ethers.providers.JsonRpcProvider(RpcUrl)
            const presalContract = new ethers.Contract(PresaleSmartContractAddress,PRESALEABI,provider)

            const sold = await presalContract.sold()
            const hexToDecimal = (hex) => parseInt(hex, 16);
            let number = hexToDecimal(sold._hex);

            let percentage = number/20000000

            percentage = percentage * 100

            percentage = Math.floor(percentage)

            percentage = percentage.toString()

            setSold(percentage)
            
            // console.log(percentage)

        }
        catch(error){

        }

    }


    useEffect( () => {

        if (!Sold) {
            getProgress()
        }
        
    }, [] )

    return (

        <div className='presale_publicsale_div' >

            <div className="presale_publicsale_div_header" >

                <h5>Private Sale                
                    <img src={UnderlineImg} alt="underline" />

                </h5>

            </div>

            <div className='presale_publicsale_div_body' >
                
                <h4 className='presale_publicsale_div_ised' >Funds raised:</h4>

                <div className='presale_publicsale_div_progress' >
                    <motion.div className='presale_publicsale_div_progress_bar'
                    initial={{ width: '0%' }}
                    whileInView={{ width: Sold ? `${Sold}%` : '0%' }}
                    transition={{ duration: 0.6 }}
                    style={{
                        width: Sold ? `${Sold}%` : '0%'
                    }} ></motion.div>
                </div>

                <div className='presale_publicsale_div_caution' >
                    <PiWarningOctagonFill className='presale_publicsale_div_caution_ic' />
                    <h6>Make sure that you’re contibuting WETH</h6>
                </div>

                <h4 className='presale_publicsale_div_contribute' >
                    Contribute WETH (1 $Relay: 0.0000037 WETH)
                </h4>

                <h4 className='presale_publicsale_div_ipsum' >
                Reminder: "Your $RLY tokens will be eligible for claiming exclusively following the Token Generation Event (TGE) and will subsequently undergo a mandatory 3-day vesting period.
                </h4>

                { user_account ?
                
                    <>
                    
                        <input type='text' placeholder='Enter the ammount in WETH' className='presale_publicsale_div_textBox' value={amountToContribute} onChange={ (e) => setamountToContribute(e.target.value) } />

                        <div className='presale_publicsale_div_fin' >
                            <button disabled={ isLoading } onClick={ () => approveHandler() } >{ isLoading ? <Spinner
                                color="default" size="sm"
                            /> : 'Approve WETH' }</button>
                            <button disabled={isLoading} onClick={ () => contributeHandler() } >{ isLoading ? <Spinner
                                color="default" size="sm"
                            /> : 'Contribute WETH' }</button>
                        </div>

                    </>
                
                : <button onClick={ () => enableWeb3() } className='presale_publicsale_div_btnBm' >
                    Connect Wallet
                </button> }

            </div>


            {/* <img src={EclipseImg} alt="ss" className='presale_publicsale_div_leftImg' />
            <img src={RightImg} alt="ss" className='presale_publicsale_div_rightImg' />
            <img src={RightEcliImg} alt="ss" className='presale_publicsale_div_miniRight' />
            <img src={LeftEcliImg} alt="ss" className='presale_publicsale_div_miniLeft' /> */}

            { isSuccess.status ? 
            
                <EditableSuccessModal
                    closeModal={ () => setisSuccess({
                        status:false,
                        title:"",
                        message:""
                    }) }
                    modal_message={ isSuccess.message }
                    modal_title={isSuccess.title}
                    presale={true}
                />
            
            : <></> }

            { iserror.status ?
            
            
                <ErrorModal2
                    closeModal={ () => setiserror({
                        status:false,
                        title:"",
                        message:""
                    }) }
                    msg={iserror.message}
                    presale={true}
                />

            : <></> }

        </div>

    );

}

export default PublicSaleComponent;
